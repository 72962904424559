import React from 'react';
import { Box, Card, CardContent, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { formatTimeRemaining, formatOvertime } from '../Utilities/time';
import EditIcon from '@material-ui/icons/Edit';
import { Visibility, VisibilityOff } from '@material-ui/icons';

function ClientCard(props) {
    const style = {
        height: "100%"
    }

    const breakPct = 100 / props.client.colors.length;

    const gradients = props.client.colors.map((color, index) => {
        return `${color} ${index * breakPct + 0}%, ${color} ${(index + 1) * breakPct}%`;
    });

    const backgroundStyle = {
        width: '100%',
        background: `linear-gradient(110deg, ${gradients.join(',')})`,
        textAlign: 'right',
        paddingRight: '5px'
    }

    return (
    <Card style={style} className={props.client.remainingCommittedHours <= 0 || props.client.isHidden ? "completed" : ""}>
        <Box style={backgroundStyle}>
            <IconButton className={"cardEditIcon"} aria-label="edit" onClick={() => props.onEdit(props.client)}>
                <EditIcon />
            </IconButton>
            <IconButton className={"toggleHideIcon"} aria-label="toggleHide" onClick={() => props.onToggleHide(props.client)}>
                {props.client.isHidden ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </Box>
        <CardContent>
            <LinearProgress variant="determinate" value={((props.client.totalCommittedHours - props.client.remainingCommittedHours) / props.client.totalCommittedHours) * 100} />
            <Typography gutterBottom variant="h6" component="h2">
                {props.client.name}
            </Typography>
            <Typography>
                Remaining: {formatTimeRemaining(props.client.remainingCommittedHours)}
            </Typography>
            <Typography>
                Commited: {formatTimeRemaining(props.client.totalCommittedHours)} {formatOvertime(props.client.overtimeHours)}
            </Typography>
            <Typography>
                Today's Target Pace: {formatTimeRemaining(props.client.dayStartAverageHoursPerDayRemaining)}
            </Typography>
            <Typography>
                Worked Today: {formatTimeRemaining(props.client.todaySpentHours - props.client.todayOvertimeHours)} {formatOvertime(props.client.todayOvertimeHours)}
            </Typography>
            </CardContent>
    </Card>);
}

export default ClientCard;
