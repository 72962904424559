import React from "react";
import {
    Button,
    TextField,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
} from "@material-ui/core";
class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { username: "", password:"", authflag:1 };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onLoginAttempt = this.props.onLoginAttempt;
    }
    handleChange = (event) => {
      this.setState({ username: event.state.username, password: event.state.password });
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.onLoginAttempt({ username: this.state.username, password: this.state.password });
    }
    onEnter = (event) => {
        if(event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmit(event);
        }
    }
    render() {
        return (
        <div>
        <Grid container spacing={0} justify="center" alignItems="center" direction="row" className="loginRoot">
            <Grid item>
                <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                className="login-form"
                >
                    <Card className="login-background">
                        <CardHeader title={"Sign in"}/>
                        <CardContent>
                        <TextField type="email" placeholder="Email"
                                            fullWidth
                                            name="username"
                                            variant="outlined"
                                            value={this.state.username}
                                            onChange={(event) =>
                                            this.setState({
                                            [event.target.name]: event.target.value,
                                            })
                                            }
                                            required
                                            autoFocus
                                            />
                        <TextField
                                            type="password"
                                            placeholder="Password"
                                            fullWidth
                                            name="password"
                                            variant="outlined"
                                            value={this.state.password}
                                            onChange={(event) =>
                                            this.setState({
                                            [event.target.name]: event.target.value,
                                            })
                                            }
                                            required
                                            onKeyPress={this.onEnter}
                                            />
                        </CardContent>
                        <CardActions className="loginFormActions">
                            <Button onClick={this.handleSubmit}>Login</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
        </div>
        );
    }
}
export default LoginForm;