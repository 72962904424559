import React from 'react';
import { Box, Card, CardContent, CardHeader, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useConfirm } from 'material-ui-confirm';

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

function TimeOffCard(props) {
    const confirm = useConfirm();

    let start = new Date(props.entry.start);
    let end = new Date(props.entry.end);

    const segments = [];

    // This means it goes through the end of the month, so roll back to the very end of the month
    while(start.getDate() > end.getDate()) {
        end.setSeconds(end.getSeconds()-1);
    }

    while(start.getDate() <= end.getDate()) {
        if(start.getHours() <= 9 && (end.getDate() !== start.getDate() || end.getHours() >= 17)) {
            // All day
            segments.push({ value: `${start.getMonth()+1}/${start.getDate()}/${start.getFullYear()} - All Day` });
        } else if(start.getHours() > 9 && (end.getDate() !== start.getDate() || end.getHours() >= 17)) {
            // Afternoon off
            segments.push({ value: `${start.getMonth()+1}/${start.getDate()}/${start.getFullYear()} - Off After ${formatAMPM(start)}` });
        } else if(start.getHours() <= 9 && start.getDate() === end.getDate() && end.getHours() < 17) {
            // Morning off
            segments.push({ value: `${start.getMonth()+1}/${start.getDate()}/${start.getFullYear()} - Off Until ${formatAMPM(end)}` });
        } else if(start.getDate() === end.getDate()) {
            // Portion off
            segments.push({ value: `${start.getMonth()+1}/${start.getDate()}/${start.getFullYear()} - Off ${formatAMPM(start)} - ${formatAMPM(end)}` });
        }

        start.setDate(start.getDate() + 1);
        start.setHours(8);

        // This means we wrapped around to the next month so we can bail
        if(start.getDate() === 1) {
            break;
        }
    }

    return (
    <Card className={"timeOffCard"}>
        <CardHeader
            action={
            <Box>
                <IconButton aria-label="delete" onClick={() => {
                    confirm({ title: `Delete "${props.entry.description}"`, description: `Are you sure you want to delete this time off?` })
                        .then(() => props.onDelete(props.entry.id));
                }}>
                    <DeleteIcon />
                </IconButton>
            </Box>
            }
            title={props.entry.description}
        />
        <CardContent>
            {segments.map((segment, index) => ( <Typography key={index}>{segment.value}</Typography> ))}
        </CardContent>
    </Card>);
}

export default TimeOffCard;
