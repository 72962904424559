import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';

class ClientDialog extends React.Component {
    isCommittedHoursValid = () => {
        return this.props.client?.totalCommittedHours >= 0;
    };

    handleCancel = () => {
        this.props.onCancel(null);
    };

    handleSubmit = () => {
        this.props.onComplete();
    };

    handleCommittedHoursChange = (e) => {
        this.props.onChange({...this.props.client, totalCommittedHours: parseFloat(e.target.value)});
    };

    render = () => (
        <Box>
            <Dialog fullWidth onEnter={this.handleOpen} open={this.props.open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
               <DialogTitle id="form-dialog-title">Edit {this.props.client?.name}'s Hours</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus required onChange={this.handleCommittedHoursChange} 
                        id="standard-number" label="Committed Hours" type="number"
                        value={this.props.client?.totalCommittedHours}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" type="submit" disabled={!this.isCommittedHoursValid()}>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default ClientDialog;