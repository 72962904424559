import React, { Component } from 'react';
import './App.css';
import { Grid, Box, Typography, Card, CardContent, createMuiTheme, ThemeProvider, LinearProgress, IconButton, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClientCard from './Components/ClientCard';
import { formatTimeRemaining, formatOvertime } from './Utilities/time';
import TimeOffCard from './Components/TimeOffCard';
import TimeOffDialog from './Components/TimeOffDialog';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ConfirmProvider } from 'material-ui-confirm';
import ClientDialog from './Components/ClientDialog';
import LoginForm from './Components/LoginForm';

const rootUrl = process.env.NODE_ENV === 'production' ? 'https://api.lookatmycode.com/' : 'https://localhost:44366/';

const defaultClient = {
  id: null,
  name: '',
  totalCommittedHours: 0
}

const theme = createMuiTheme({
  typography: {
    fontSize: 11,
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: null,
      dialogOpen: false,
      selectedClient: defaultClient,
      accessToken: localStorage.getItem('accessToken') === "null" ? null : localStorage.getItem('accessToken')
    };
  }

  logout = () => {
    localStorage.setItem("accessToken", null);
    this.setState({...this.state, accessToken: null});
  }

  handleResponse = (response) => {
    if(response.status < 400) {
      return response.json();
    } else if(response.status === 401) {
      this.logout();
    } else {
      throw response;
    }
  }

  handleUncaughtError = (response) => {
    console.log(response);
  }

  getSummary = () => {
    fetch(rootUrl + 'summary',
      {
        headers: {
          'Authorization': 'Bearer ' + this.state.accessToken
        }
      })
      .then(response => this.handleResponse(response))
      .then(data => this.setState({info: data}))
      .catch(this.handleUncaughtError);
  }

  getTimeOffEntries = () => {
    fetch(rootUrl + 'timeoffentries',
    {
      headers: {
        'Authorization': 'Bearer ' + this.state.accessToken
      }
    })
      .then(response => this.handleResponse(response))
      .then(data => this.setState({timeOff: data}))
      .catch(this.handleUncaughtError);
  }

  onTimeOffAddClick = () => {
    this.setState({dialogOpen: true});
  }

  onTimeOffDelete = (timeOffId) => {
    fetch(rootUrl + 'timeoffentries/' + timeOffId, 
      {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + this.state.accessToken
        }
      })
      .then(() => {
        this.setState({ timeOff: this.state.timeOff.filter(item => item.id !== timeOffId) });
      })
      // TODO remove item from timeout array
      .then(() => {
        this.getSummary();
      })
      .catch(this.handleUncaughtError);
  }

  onCloseTimeOff = (value) => {
    if(value) {
      if(value.id) {
        // TODO update
      }
      else
      {
        fetch(rootUrl + 'timeoffentries', 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.state.accessToken
            },
            body: JSON.stringify(value)
          })
          .then(response => this.handleResponse(response))
          .then(data => {
            const newTimeOff = this.state.timeOff;
            newTimeOff.push(data);
            this.setState({timeOff: newTimeOff})
          })
          .then(() => {
            this.getSummary();
          })
          .catch(this.handleUncaughtError);
      }
    }

    this.setState({dialogOpen: false});
  }

  onClientEdit = (client) => {
    this.setState({selectedClient: client});
  }

  onClientToggleHide = (client) => {
    fetch(rootUrl + 'clients/' + client.id, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.state.accessToken
        },
        body: JSON.stringify({
          committedHours: client.totalCommittedHours,
          isHidden: !client.isHidden
        })
      })
      .then(response => this.handleResponse(response))
      .then(() => {
        var existingClient = this.state.info.clients.find(x => x.id === client.id);
        existingClient.isHidden = !existingClient.isHidden;
        this.setState({info: {
          ...this.state.info
        }});
      })
      .catch(this.handleUncaughtError);
  }

  onClientEditCancel = () => {
    this.setState({selectedClient: defaultClient});
  }

  onClientEditComplete = () => {
    fetch(rootUrl + 'clients/' + this.state.selectedClient.id, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.state.accessToken
        },
        body: JSON.stringify({
          committedHours: this.state.selectedClient.totalCommittedHours,
          isHidden: this.state.selectedClient.isHidden
        })
      })
      .then(response => this.handleResponse(response))
      .then(() => {
        this.setState({selectedClient: {...this.state.selectedClient, id: null}});
      })
      .then(() => {
        this.getSummary();
      })
      .catch(this.handleUncaughtError);
  }

  onLoginAttempt = (event) => {
    fetch(rootUrl + 'token', 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(event)
    })
    .then(response => response.json())
    .then((response) => {
      if(response.accessToken) {
        localStorage.setItem("accessToken", response.accessToken);
        this.setState({accessToken: response.accessToken}, () => {
          this.getSummary();
          this.getTimeOffEntries();
        });
      } else {
        alert("Invalid username or password.");
      }
    })
    .then(() => {
      this.getSummary();
    })
    .catch(() => {
      this.logout();
      alert('Unable to contact server to login.');
    });
  }

  componentDidMount() {
    this.getSummary();
    this.getTimeOffEntries();

    this.interval = setInterval(this.getSummary, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render () {
    if (this.state.accessToken && this.state.info && this.state.timeOff) {
      return (
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ConfirmProvider>
              <Box padding={4}>
                <TimeOffDialog open={this.state.dialogOpen} onClose={this.onCloseTimeOff}/>
                <ClientDialog onChange={this.onClientEdit} open={this.state.selectedClient.id} client={this.state.selectedClient} onCancel={this.onClientEditCancel} onComplete={this.onClientEditComplete}/>
                <Grid container spacing={2} justify="center">
                  <Grid item xs={12}>
                      <Card>
                        <CardContent>
                        <LinearProgress variant="determinate" value={((this.state.info.totalCommittedHours - this.state.info.remainingCommittedHours) / this.state.info.totalCommittedHours) * 100} />
                        <Typography gutterBottom variant="h4" component="h1">
                          Summary
                        </Typography>
                        <Typography>
                            Remaining: {formatTimeRemaining(this.state.info.remainingCommittedHours)}
                        </Typography>
                        <Typography>
                            Commited: {formatTimeRemaining(this.state.info.totalCommittedHours)} {formatOvertime(this.state.info.overtimeHours)}
                        </Typography>
                        <Typography>
                            Overall Monthly Pace: {formatTimeRemaining(this.state.info.averageHoursPerDayRemaining)} per day
                        </Typography>
                        <Typography>
                            Today's Target Pace: {formatTimeRemaining(this.state.info.dayStartAverageHoursPerDayRemaining)}
                        </Typography>
                        <Typography>
                            Worked Today: {formatTimeRemaining(this.state.info.todaySpentHours - this.state.info.todayOvertimeHours)} {formatOvertime(this.state.info.todayOvertimeHours)}
                        </Typography>
                        </CardContent>
                      </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="sectionHeader">Time Off</Typography>
                  </Grid>
                  {this.state.timeOff.map((entry) => ( <Grid item xs={12} sm={6} md={4} key={entry.id}><TimeOffCard onDelete={this.onTimeOffDelete} entry={entry}/></Grid> ))}
                  <Grid item xs={12} sm={6} md={4}>
                    <IconButton aria-label="add" className="addTimeButton" onClick={this.onTimeOffAddClick}>
                      <AddIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="sectionHeader">Clients</Typography>
                  </Grid>
                  {this.state.info.clients.map((client) => ( <Grid item xs={12} sm={6} md={4} key={client.id}><ClientCard onEdit={this.onClientEdit} client={client} onToggleHide={this.onClientToggleHide}/></Grid> ))}
                </Grid>
              </Box>
              <Button className="logoutButton" onClick={this.logout}>Logout</Button>
            </ConfirmProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>);
    } else if(this.state.accessToken) {
      const boxStyle = {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        bottom: '50%',
        width: '100%'
      };
      const fontStyle = {
        marginTop: '-1.5em'
      };
      return <ThemeProvider theme={theme}>
          <Box style={boxStyle}>
            <div className="loader"></div>
            <Typography style={fontStyle}>Calculating...</Typography>
          </Box>
        </ThemeProvider>
    } else {
      return <ThemeProvider theme={theme}>
        <LoginForm onLoginAttempt={this.onLoginAttempt} />
      </ThemeProvider>
    }
  }
}

export default App;
