import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import { setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

const defaultState = {
    timeOff: {
        id: null,
        description: "",
        start: null,
        end: null
    },
    open: false,
    allowTimeAdjustment: false,
    allowMultiDay: false
}

class TimeOffDialog extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = defaultState;
    }

    isTimeOffValid = () => {
        return this.state.timeOff.start && this.state.timeOff.end && this.state.timeOff.description && this.state.timeOff.start < this.state.timeOff.end;
    };

    reset = () => {
        this.setState(defaultState);
    };

    handleOpen = () => {
        /*if(this.context.selectedTransaction){
            this.setState({...this.state, timeOff: this.context.selectedTimeOff});
        }*/
    };

    handleClosed = () => {
        this.reset();
    };

    handleCancel = () => {
        this.props.onClose(null);
    };

    handleSubmit = () => {
        this.props.onClose(this.state.timeOff);
    };

    updateTimeOff = (updates, callback) => {
        this.setState({...this.state, timeOff: { ...this.state.timeOff, ...updates }}, callback);
    };

    handleDescriptionChange = (e) => {
        const description = e.target.value;
        this.updateTimeOff({ description }, function() {
            if(description === "") {
                this.setState({ descriptionError: "Description is required" });
            }else{
                this.setState({ descriptionError: "" });
            }
        });
    };

    dialogTitle = () => {
        if(this.state.timeOff.id) {
            return "Edit Time Off";
        }

        return "Add Time Off";
    };

    submitButtonLabel = () => {
        if(this.state.timeOff.id) {
            return "Update";
        }

        return "Add";
    };

    onTimeAdjustmentCheckChanged = (event) => {
        this.setState({...this.state, allowTimeAdjustment: !event.target.checked});
    };

    onMultiDayCheckChanged = (event) => {
        this.setState({...this.state, allowMultiDay: event.target.checked});
    };

    setTimeToMin = (value) => {
        return setHours(setMinutes(setSeconds(setMilliseconds(value, 0), 0), 0), 0);
    };

    setTimeToMax = (value) => {
        return setHours(setMinutes(setSeconds(setMilliseconds(value, 0), 59), 59), 23);
    };

    onStartChange = (value) => {
        // TODO make sure end is greater than or equal to start

        this.updateTimeOff({
            start: this.state.allowTimeAdjustment ? value : this.setTimeToMin(value),
            end: this.state.timeOff.end ?? this.setTimeToMax(value)
        });
    };

    onEndChange = (value) => {
        // TODO make sure start is less than or equal to end

        this.updateTimeOff({
            start: this.state.timeOff.start ?? this.setTimeToMin(value),
            end: this.state.allowTimeAdjustment ? value : this.setTimeToMax(value)
        });
    };

    render = () => (
        <Box>
            <Dialog fullWidth onEnter={this.handleOpen} onExited={this.handleClosed} open={this.props.open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.dialogTitle()}</DialogTitle>
                <DialogContent>
                    <TextField error={this.state.descriptionError} helperText={this.state.descriptionError} autoFocus required onChange={this.handleDescriptionChange} margin="dense" id="description" label="Description" value={this.state.timeOff.description} fullWidth/>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={!this.state.allowTimeAdjustment}
                            onChange={this.onTimeAdjustmentCheckChanged}
                            name="timeAdjustment"
                            color="primary"
                        />
                        }
                        label="All Day"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.allowMultiDay}
                            onChange={this.onMultiDayCheckChanged}
                            name="multiDay"
                            color="primary"
                        />
                        }
                        label="Multi Day"
                    />
                    <Box>
                        <DatePicker
                            id="startDate"
                            label={this.state.allowMultiDay ? "Start date" : "Date"}
                            value= {this.state.timeOff.start}
                            onChange={this.onStartChange}
                            disablePast={true}
                            autoOk={true}/>
                        <TimePicker
                            id="startTime"
                            label={this.state.allowMultiDay ? "Start time" : "Time"}
                            value= {this.state.timeOff.start}
                            onChange={this.onStartChange}
                            style={this.state.allowTimeAdjustment ? {} : { display: 'none' }}
                            autoOk={true}/>
                        <Box style={this.state.allowMultiDay ? {} : { display: 'none' }}>
                            <DatePicker
                                id="endDate"
                                label="End date"
                                value= {this.state.timeOff.end}
                                onChange={this.onEndChange}
                                disablePast={true}
                                minDate={this.state.timeOff.start}
                                autoOk={true}/>
                            <TimePicker
                                id="endTime"
                                label="End time"
                                value= {this.state.timeOff.end}
                                onChange={this.onEndChange}
                                style={this.state.allowTimeAdjustment ? {} : { display: 'none' }}
                                autoOk={true}/>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" type="submit" disabled={!this.isTimeOffValid()}>
                        {this.submitButtonLabel()}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default TimeOffDialog;