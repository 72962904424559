function hoursTohhmm(hours) {
    const sign = hours < 0 ? "-" : "";
    const hh = Math.floor(hours);
    const mm = Math.floor(hours * 60 % 60);
  
    return sign + hh + ":" + (mm < 10 ? "0" : "") + mm;
  }

  function formatTimeRemaining(hours) {
    const sign = hours < 0 ? "-" : "";
    const hh = Math.abs(Math.floor(hours));
    const mm = Math.floor(hours * 60 % 60);
  
    if(hh === 0 && mm === 0) {
      return "0m";
    }

    let retVal = sign;
    if(hh > 0) {
      retVal += `${hh}h`;

      if(mm > 0) {
        retVal += " ";
      }
    }

    if(mm > 0) {
      retVal += `${mm}m`;
    }

    return retVal;
  }

  function formatOvertime(overtime) {
    if(overtime <= 0) {
        return "";
    }

    return "+(" + formatTimeRemaining(overtime) + " overtime)";
}

  export { hoursTohhmm, formatTimeRemaining, formatOvertime };
